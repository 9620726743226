.model-viewer-container {
     width: 100%;
     height: 80vh;
}

@media (max-width: 768px) {
     .model-viewer-container {
          height: 70vh;
     }
}

@media (max-width: 480px) {
     .model-viewer-container {
          height: 70vh;
     }
}


@media (max-width: 1024px) {
     .model-viewer-container {

          height: 500px !important;
     }
}

@media (max-width: 1280px) {
     .model-viewer-container {

          height: 700px;
     }
}